import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { getStrapiUri } from "../../../lib/get-strapi-uri";
import { useState } from "react";

const formSchema = z.object({
	fullName: z.string().trim().min(3, "Imię i nazwisko jest za krótkie"),
	email: z.string().trim().email("Niepoprawny email"),
	topic: z.string().trim().min(3, "Imię i nazwisko jest za krótkie"),
	text: z
		.string()
		.trim()
		.min(10, "Wiadomosc jest za krotka")
		.max(5000, "Wiadomosc jest za długa"),
});

export function ContactForm() {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			fullName: "",
			email: "",
			topic: "",
			text: "",
		},
	});

	const isSubmitting = form.formState.isSubmitting;

	const { errors } = form.formState;

	const [submitError, setSubmitError] = useState("");
	const [onSuccess, setOnSuccess] = useState("");

	async function onSubmit(values: z.infer<typeof formSchema>) {
		try {
			setSubmitError("");
			setOnSuccess("");

			const formData = new FormData();
			formData.append("data", JSON.stringify({ ...values }));

			await axios.post(getStrapiUri("/api/contact-requests"), { data: values });

			setOnSuccess("Wiadomość została wysłana");

			form.reset();
		} catch (error) {
			setSubmitError("Wystąpił błąd podczas wysyłania wiadomości");
		}
	}

	return (
		<FormProvider {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="w-full max-w-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 mt-14"
			>
				<Controller
					control={form.control}
					name="fullName"
					render={({ field }) => (
						<div>
							<label className="text-sm oswald-font">
								Imię i nazwisko
								<span className="text-red-600">*</span>
							</label>
							<input
								{...field}
								type="fullName"
								autoComplete="name"
								disabled={isSubmitting}
								className="border-2 border-black p-2 w-full bg-transparent focus:outline-none"
							/>
							{errors.fullName && (
								<p className="text-red-600 text-sm">
									{errors.fullName.message}
								</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="email"
					render={({ field }) => (
						<div>
							<label className="text-sm oswald-font">
								Adres email
								<span className="text-red-600">*</span>
							</label>
							<input
								{...field}
								type="email"
								autoComplete="email"
								disabled={isSubmitting}
								className="border-2 border-black p-2 w-full bg-transparent focus:outline-none"
							/>
							{errors.email && (
								<p className="text-red-600 text-sm">{errors.email.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="topic"
					render={({ field }) => (
						<div className="lg:col-span-2">
							<label className="text-sm oswald-font">
								Temat
								<span className="text-red-600">*</span>
							</label>
							<input
								{...field}
								type="topic"
								disabled={isSubmitting}
								className="border-2 border-black p-2 w-full bg-transparent focus:outline-none"
							/>
							{errors.email && (
								<p className="text-red-600 text-sm">{errors.email.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="text"
					render={({ field }) => (
						<div className="lg:col-span-2">
							<label className="text-sm oswald-font">
								Treść wiadomości
								<span className="text-red-600">*</span>
							</label>
							<textarea
								{...field}
								rows={8}
								disabled={isSubmitting}
								className="border-2 border-black p-2 w-full bg-transparent focus:outline-none"
							/>
							{errors.text && (
								<p className="text-red-600 text-sm">{errors.text.message}</p>
							)}
						</div>
					)}
				/>

				{!!submitError && (
					<div className="lg:col-span-2 text-center text-lg text-red-600">
						<p>{submitError}</p>
					</div>
				)}

				{!!onSuccess && (
					<div className="lg:col-span-2 text-center text-lg">
						<p>{onSuccess}</p>
					</div>
				)}

				<div className="lg:col-span-2">
					<button
						type="submit"
						disabled={isSubmitting}
						className="border-2 border-black hover:bg-[#9acd32] hover:text-white duration-200 p-2 w-full bg-transparent text-center oswald-font uppercase font-semibold"
					>
						{isSubmitting ? "Wysyłanie..." : "Wyślij"}
					</button>
				</div>
			</form>
		</FormProvider>
	);
}
