import { Home } from "../../components/home/home";
import { About } from "../../components/about/about";
import { Clients } from "../../components/clients/clients";
import { Contact } from "../../components/contact/contact";
import { Info } from "../../components/info/info";
import { Offer } from "../../components/offer/offer";
import { OfferItemType } from "../../types/strapi-types";

export function MainPage({ offerPages }: { offerPages?: OfferItemType[] }) {
	return (
		<div>
			<Home offerPages={offerPages} />

			<Offer offerPages={offerPages} />

			<About />

			<Clients />

			<Contact />

			<Info />
		</div>
	);
}
