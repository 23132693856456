import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { FooterType } from "../../types/strapi-types";

export function Footer() {
	const { data } = useStrapiFetch<FooterType>("/api/footer?populate=*");

	if (!data) {
		return null;
	}

	const { logo, text } = data;

	return (
		<div className="bg-black/85 text-white">
			<footer className="w-full container mx-auto px-4 md:py-10 py-6">
				<div className="w-40 h-20 relative mx-auto">
					<img
						src={getStrapiImageUrl(logo.url)}
						alt={logo.alternativeText}
						className="absolute top-0 left-0 w-full h-full object-contain"
					/>
				</div>

				<ReactMarkdown className="text-xs uppercase text-center font-semibold text-white/70 [&_a]:hover:text-[#9acd32] [&_a]:duration-200">
					{text}
				</ReactMarkdown>
			</footer>
		</div>
	);
}
