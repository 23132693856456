import { NavLink } from "react-router-dom";

export function NotFoundPage() {
	return (
		<div className="fixed top-0 left-0 z-50 bg-white w-screen h-screen flex flex-col md:flex-row justify-center items-center gap-6">
			<p className="font-semibold text-xl">Strona nie znaleziona</p>
			<NavLink to="/" className="px-10 py-2 rounded-full border">
				Wróć
			</NavLink>
		</div>
	);
}
