import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { AboutItemType, AboutType } from "../../types/strapi-types";
import { Title } from "../title/title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function About() {
	const { data } = useStrapiFetch<AboutType>("/api/about?populate=*");
	const { data: items } = useStrapiFetch<AboutItemType[]>(
		"/api/about-items?populate=*"
	);

	if (!data || !items) {
		return null;
	}

	const { text } = data;

	return (
		<div className="relative">
			<div
				className="absolute top-0 left-0 w-full h-full bg-black/85"
				style={{ backgroundImage: "url(/theme/assets/pattern-black.png)" }}
			/>

			<div
				id="o-nas"
				className="md:py-24 py-16 container px-4 mx-auto relative z-10 text-white"
			>
				<Title title="O nas" />

				<ReactMarkdown className="text-sm text-center">{text}</ReactMarkdown>

				<div className="mt-14 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8 justify-center items-stretch">
					{items.map((item, index) => (
						<div key={`about-${index}`} className="group w-full flex flex-col">
							<div className="w-full max-w-32 mx-auto">
								<div className="relative w-full h-0 pb-[100%] rounded-full duration-200 group-hover:bg-[#9acd32]">
									<FontAwesomeIcon
										icon={item.fontAwesomeIcon as any}
										className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl text-[#9acd32] group-hover:text-black duration-200"
									/>
									<div className="absolute w-full h-full top-0 left-0 rounded-full border border-[#9acd32] group-hover:scale-110 duration-200"/>
								</div>
							</div>

							<h4 className="text-2xl oswald-font my-5 text-center uppercase">
								{item.title}
							</h4>

							<ReactMarkdown className="text-sm text-center flex-1">
								{item.text}
							</ReactMarkdown>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
