import { Content } from "../../components/offer/content/content";
import { Title } from "../../components/title/title";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { OfferItemType } from "../../types/strapi-types";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";

export function OfferPage({ id }: { id: number }) {
	const { data } = useStrapiFetch<OfferItemType[]>(
		`/api/offer-items?filters[id][$eq]=${id}&populate=*`
	);

	if (!data || !data[0]) {
		return null;
	}

	const { title, text, isList } = data[0];

	const htmlTitle = title[0].toUpperCase() + title.slice(1).toLocaleLowerCase();

	return (
		<>
			<Helmet>
				<title>{htmlTitle} - Project4Data</title>
			</Helmet>

			<div className="md:py-24 py-16 container max-w-4xl px-4 mx-auto">
				<HashLink
					to="/#oferta"
					className="text-sm hover:text-[#9acd32] duration-200"
				>
					{"<<"} wróć do oferty
				</HashLink>

				<Title title={title} />

				<Content text={text} isList={isList} />
			</div>
		</>
	);
}
