import { HashLink } from "react-router-hash-link";

const links = [
	{
		href: "/#home",
		text: "Strona główna",
	},
	{
		href: "./#oferta",
		text: "Oferta",
	},
	{
		href: "./#o-nas",
		text: "O nas",
	},
	{
		href: "./#kontakt",
		text: "Kontakt",
	},
];

export function NavigationLinks({ closeMenu }: { closeMenu?: () => void }) {
	return (
		<>
			{links.map((link) => (
				<div
					key={link.href}
					className="px-4 hover:text-[#9acd32] duration-200 relative h-12 flex items-center justify-center tracking-widest oswald-font font-bold uppercase"
					onClick={closeMenu}
				>
					<HashLink to={link.href}>{link.text}</HashLink>
				</div>
			))}
		</>
	);
}
