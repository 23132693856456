import { Error } from "./components/error/error";
import { useAppErrorStore } from "./hooks/use-app-error-store";
import { OfferItemType } from "./types/strapi-types";
import { useStrapiFetch } from "./hooks/use-strapi-fetch";
import { Route, Routes } from "react-router-dom";
import { useAppLoadingStore } from "./hooks/use-app-loading-store";
import { NotFoundPage } from "./pages/not-found-page/not-found-page";
import { MainPage } from "./pages/main-page/main-page";
import { Loader } from "./components/loader/loader";
import { Header } from "./components/header/header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Footer } from "./components/footer/footer";
import { OfferPage } from "./pages/offer-page/offer-page";

library.add(fas, far, fab);

export default function App() {
	const { data: offerPages } = useStrapiFetch<OfferItemType[]>(
		"/api/offer-items?populate=*&fields[0]=title&fields[1]=description&fields[2]=path"
	);

	const { isError } = useAppErrorStore();
	const { tags } = useAppLoadingStore();

	const isLoading = tags.length > 0;

	return (
		<div className="font-sans min-h-dvh flex flex-col overflow-x-hidden">
			{isError && <Error />}

			{isLoading && <Loader />}

			<Header />

			<main className="flex-1">
				<Routes>
					<Route path="" element={<MainPage offerPages={offerPages} />} />
					{!!offerPages &&
						offerPages.map((offer) => (
							<Route
								key={offer.path}
								path={offer.path}
								element={<OfferPage id={offer.id} />}
							/>
						))}
					<Route path="/*" element={<NotFoundPage />} />
				</Routes>
			</main>

			<Footer />
		</div>
	);
}
