import { useRef } from "react";
import { getStrapiImageUrl } from "../../../lib/get-strapi-image-url";
import { OfferItemType } from "../../../types/strapi-types";
import { useParallax } from "../../../hooks/use-parallax";

export function Item({ offer }: { offer: OfferItemType }) {
	const bgRef = useRef<HTMLImageElement>(null);
	useParallax(bgRef);

	return (
		<div className="relative w-full h-dvh">
			<img
				ref={bgRef}
				src={getStrapiImageUrl(offer.bg.url)}
				alt={offer.bg.alternativeText}
				className="absolute top-0 left-0 w-full h-full object-cover z-10"
			/>

			<div className="absolute top-0 left-0 w-full h-full bg-[#1d2127]/80 z-20" />

			<div className="relative flex w-full h-full justify-center items-center z-30">
				<h3 className="text-white oswald-font uppercase md:text-6xl text-5xl text-center leading-tight">
					{offer.title}
				</h3>
			</div>
		</div>
	);
}
