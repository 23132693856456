import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

export function Item({
	title,
	text,
	firstItem,
}: {
	title: string;
	text: string;
	firstItem: boolean;
}) {
	const [isExpanded, setIsExpanded] = useState(firstItem);
	const itemRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function handleMouse(event: MouseEvent) {
			if (!isExpanded || !itemRef.current || !event.target) {
				return;
			}

			if (itemRef.current.contains(event.target as Node)) {
				return;
			}

			setIsExpanded(false);
		}

		document.addEventListener("click", handleMouse);
		return () => document.removeEventListener("click", handleMouse);
	}, [itemRef, isExpanded]);

	useEffect(() => {
		if (!contentRef.current) {
			return;
		}

		if (isExpanded) {
			contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
			contentRef.current.style.transform = "translateY(0)";
			return;
		}

		contentRef.current.style.height = "0";
		contentRef.current.style.transform = "translateY(-1px)";
	}, [contentRef, isExpanded]);

	return (
		<div ref={itemRef}>
			<button
				className="flex justify-between items-center py-2 px-4 border-[#ccc] text-[#9acd32] border cursor-pointer w-full"
				onClick={() => setIsExpanded((prev) => !prev)}
			>
				<h4 className="text-lg text-start">{title}</h4>

				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="none"
					stroke="#9acd32"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`w-8 h-8 duration-500 ${isExpanded ? "rotate-180" : ""}`}
				>
					<path d="m18 15-6-6-6 6" />
				</svg>
			</button>

			<div
				ref={contentRef}
				className="duration-500 overflow-hidden border-[#ccc] border border-t-0"
			>
				<div className="py-2 px-4">
					<ReactMarkdown className="text-sm [&_li]:list-disc [&_li]:list-inside marker:text-[#9acd32]">
						{text}
					</ReactMarkdown>
				</div>
			</div>
		</div>
	);
}
