import { useRef } from "react";
import { useParallax } from "../../hooks/use-parallax";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { InfoType } from "../../types/strapi-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Info() {
	const { data } = useStrapiFetch<InfoType>("/api/info?populate=*");

	const bgRef = useRef<HTMLImageElement>(null);
	useParallax(bgRef);

	if (!data) {
		return null;
	}

	const { bg, tel, address, email, fb } = data;

	return (
		<div className="relative overflow-hidden bg-black text-white">
			<img
				ref={bgRef}
				src={getStrapiImageUrl(bg.url)}
				alt={bg.alternativeText}
				className="absolute top-0 left-0 w-full h-[200%] object-cover z-10"
			/>
			<div
				className="absolute top-0 left-0 w-full h-full bg-[#1d2127]/80 z-20"
				style={{ backgroundImage: "url(/theme/assets/pattern-black.png)" }}
			/>

			<div
				id="info"
				className="md:py-24 py-16 container px-4 mx-auto relative z-30 space-y-5"
			>
				<div className="flex justify-center items-center">
					<a
						href={`tel:${tel}`}
						className="group relative w-20 h-20 bg-white/5 rounded-full hover:bg-white duration-200"
					>
						<FontAwesomeIcon
							icon={"fa-solid fa-phone" as any}
							className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl group-hover:text-black duration-200"
						/>
					</a>
				</div>

				<p className="oswald-font md:text-6xl text-5xl oswald-font text-center">
					{tel}
				</p>

				<p className="text-2xl oswald-font text-center uppercase">{address}</p>

				<div className="flex justify-center items-center">
					<a
						href={`mailto:${email}`}
						className="text-2xl oswald-font text-center uppercase hover:text-[#9acd32] duration-200"
					>
						{email}
					</a>
				</div>

				<div className="flex justify-center items-center">
					<a
						href={fb}
						target="_blank"
						rel="noreferrer"
						className="relative w-16 h-16 bg-[#373a3f] rounded-full hover:bg-[#428bca] duration-200"
					>
						<FontAwesomeIcon
							icon={"fa-brands fa-facebook-f" as any}
							className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl"
						/>
					</a>
				</div>
			</div>
		</div>
	);
}
