import Slider, { CustomArrowProps, Settings } from "react-slick";
import { OfferItemType } from "../../types/strapi-types";
import { Item } from "./item/item";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NextArrow(props: CustomArrowProps) {
	const { onClick } = props;
	return (
		<div
			onClick={onClick}
			className="group absolute top-1/2 right-2 -ml-[calc(100vw-100%)] -translate-y-1/2 w-6 h-6 bg-black/30 z-10 rounded-full cursor-pointer"
		>
			<FontAwesomeIcon
				icon={"fa-solid fa-chevron-right" as any}
				className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-sm text-white group-hover:text-[#9acd32] duration-200"
			/>
		</div>
	);
}

function PrevArrow(props: CustomArrowProps) {
	const { onClick } = props;
	return (
		<div
			onClick={onClick}
			className="group absolute top-1/2 left-2 -translate-y-1/2 w-6 h-6 bg-black/30 z-10 rounded-full cursor-pointer"
		>
			<FontAwesomeIcon
				icon={"fa-solid fa-chevron-left" as any}
				className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-sm text-white group-hover:text-[#9acd32] duration-200"
			/>
		</div>
	);
}

const settings: Settings = {
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	speed: 500,
	autoplaySpeed: 5000,
	cssEase: "linear",
	dots: false,
	arrows: true,
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />,
};

export function Home({ offerPages }: { offerPages?: OfferItemType[] }) {
	if (!offerPages) {
		return null;
	}

	return (
		<div id="home" className="relative w-full">
			<Slider {...settings} className="max-w-full">
				{offerPages.map((offer, index) => (
					<Item key={`home-offer-${index}`} offer={offer} />
				))}
			</Slider>

			<div className="absolute bottom-2 left-0 w-full z-10 flex justify-center items-center">
				<HashLink
					to="/#oferta"
					className="relative group p-2 text-white hover:text-[#9acd32] duration-200 text-sm uppercase flex justify-center items-center font-semibold"
				>
					<span className="mr-2">przejdź do oferty</span>
					<div className="relative w-[0.875rem] h-[0.875rem]">
						<FontAwesomeIcon
							icon={"fa-solid fa-chevron-down" as any}
							className="absolute top-0 left-0 group-hover:translate-y-1/4 transition-transform duration-200"
						/>
						<FontAwesomeIcon
							icon={"fa-solid fa-chevron-down" as any}
							className="absolute top-0 left-0 group-hover:-translate-y-1/4 transition-transform duration-200"
						/>
					</div>
				</HashLink>
			</div>
		</div>
	);
}
