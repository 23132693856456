export function Title({ title, black }: { title: string; black?: boolean }) {
	return (
		<>
			<h3 className="md:text-6xl text-5xl oswald-font text-center uppercase">
				{title}
			</h3>

			<div
				className="h-px w-16 mx-auto my-5"
				style={{ backgroundColor: black ? "black" : "#d0d0d0" }}
			/>
		</>
	);
}
