import ReactMarkdown from "react-markdown";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { OfferItemType, OfferType } from "../../types/strapi-types";
import { NavLink } from "react-router-dom";
import { Title } from "../title/title";

export function Offer({ offerPages }: { offerPages?: OfferItemType[] }) {
	const { data } = useStrapiFetch<OfferType>("/api/offer?populate=*");

	if (!data || !offerPages) {
		return null;
	}

	const { text } = data;

	return (
		<div id="oferta" className="md:py-24 py-16 container px-4 mx-auto">
			<Title title="Nasza oferta" />

			<ReactMarkdown className="text-sm text-center">{text}</ReactMarkdown>

			<div className="mt-14 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8 justify-center items-stretch">
				{offerPages.map((offer) => (
					<div key={offer.path} className="flex flex-col">
						<h4 className="text-2xl oswald-font text-center uppercase">
							{offer.title}
						</h4>

						<ReactMarkdown className="text-sm my-5 text-center flex-1">
							{offer.description}
						</ReactMarkdown>

						<div className="flex justify-center items-center">
							<NavLink
								to={offer.path}
								className="text-lg duration-200 bg-[#9acd32]/90 hover:bg-[#9acd32] text-white py-2 px-4 rounded"
							>
								Zobacz ofertę
							</NavLink>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
