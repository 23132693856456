import { Navigation } from "./navigation/navigation";
import { Logo } from "./logo";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import type { HeaderType } from "../../types/strapi-types";

export function Header() {
	const { data } = useStrapiFetch<HeaderType>("/api/header?populate=*");

	if (!data) {
		return null;
	}

	const { logo } = data;

	return (
		<div className="fixed top-0 left-0 w-full bg-black/85 backdrop-blur-md text-white z-50">
			<header className="relative w-full container mx-auto px-4 flex justify-between gap-6 md:h-20 h-14">
				<Logo {...logo} />

				<Navigation />
			</header>
		</div>
	);
}
