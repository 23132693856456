import ReactMarkdown from "react-markdown";
import { Item } from "./item";

export function Content({ text, isList }: { text: string; isList: boolean }) {
	if (!isList) {
		return (
			<ReactMarkdown className="text-sm [&_li]:list-disc [&_li]:list-inside marker:text-[#9acd32]">
				{text}
			</ReactMarkdown>
		);
	}

	const contentList = text
		.split("-item-")
		.filter(Boolean)
		.map((v) => {
			const [iTitle, iText] = v.split("-content-");
			return {
				title: iTitle,
				text: iText,
			};
		});

	return (
		<div className="space-y-4">
			{contentList.map((v, index) => (
				<Item key={`content-${index}`} title={v.title} text={v.text} firstItem={index === 0} />
			))}
		</div>
	);
}
