import ReactMarkdown from "react-markdown";
import { Title } from "../title/title";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { ContactType } from "../../types/strapi-types";
import { ContactForm } from "./contact-form/contact-form";

export function Contact() {
	const { data } = useStrapiFetch<ContactType>("/api/contact?populate=*");

	if (!data) {
		return null;
	}

	const { text } = data;

	return (
		<div className="bg-[#ffca06]">
			<div id="kontakt" className="md:py-24 py-16 container px-4 mx-auto">
				<Title title="Skontaktuj się z nami" black />

				<ReactMarkdown className="text-sm text-center">{text}</ReactMarkdown>

				<ContactForm />
			</div>
		</div>
	);
}
