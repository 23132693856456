import { Title } from "../title/title";
import Slider, { Settings } from "react-slick";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { ClientType } from "../../types/strapi-types";
import { useMediaQuery } from "../../hooks/use-media-query";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings: Settings = {
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	autoplay: true,
	speed: 3000,
	autoplaySpeed: 0,
	cssEase: "linear",
	accessibility: false,
	draggable: false,
	swipe: false,
	touchMove: false,
};

export function Clients() {
	const { data } = useStrapiFetch<ClientType>("/api/client?populate=*");
	const { isMobile } = useMediaQuery();

	if (!data) {
		return null;
	}

	const { logos } = data;

	return (
		<div id="pracujemy-dla" className="md:py-24 py-16 w-full">
			<Title title="Pracujemy dla" />

			<div className="w-full">
				<Slider {...settings} slidesToShow={isMobile ? 2 : 3}>
					{logos.map((logo, index) => (
						<div key={`logo-${index}`} className="relative w-32 h-32">
							<img
								src={getStrapiImageUrl(logo.url)}
								alt={logo.alternativeText}
								className="absolute top-0 left-0 w-full h-full object-contain px-2"
							/>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
}
